import {Component, DestroyRef, inject} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {SchedulerService} from '../../scheduler/scheduler.service';
import {TranslateModule} from '@ngx-translate/core';

import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ExamCompletionDetail, ExamCompletionDetailItem, WorkflowItem} from "../../model";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbar} from "@angular/material/toolbar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatCheckbox} from "@angular/material/checkbox";
import {AppConfigService} from "../../app-config.service";

@Component({
    selector: 'ft-complete-exam-form',
    templateUrl: './complete-exam-form.component.html',
    styleUrls: ['./complete-exam-form.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        MatDialogClose,
        MatIconModule,
        MatToolbar,
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckbox
    ],
})
export class CompleteExamFormComponent {
    form: FormGroup;

    data = inject(MAT_DIALOG_DATA);
    #service = inject(SchedulerService);
    #fb = inject(FormBuilder);
    #dialogRef = inject(MatDialogRef<CompleteExamFormComponent>);
    #destroyRef = inject(DestroyRef);
    #config = inject(AppConfigService);


    constructor() {
        this.form = this.#fb.group({
            patientName: '',
            patientID: '',
            examCompletionDetailItems: this.#fb.array([])
        });


        const items = (this.data as WorkflowItem[]);
        const _item = items[0];

        const examDetailItems = items.map(item => new ExamCompletionDetailItem(
            item.accessionNumber,
            item.sharedWithEHealthBox,
            item.billed,
            item.billedWithEFact,
            item.noteAlert,
            item.contrast === 'C+',
            item.examType,
            item.studyInstanceUID,
            item.procedureCode
        ));

        const examCompletionDetails = new ExamCompletionDetail(
            _item.patientName,
            _item.patientID,
            examDetailItems
        );

        this.form.patchValue(examCompletionDetails);

        examDetailItems.forEach(item => {
            this.examCompletionDetailItems.push(this.#fb.group(item));
        });
    }

    get examCompletionDetailItems(): FormArray {
        return this.form.get('examCompletionDetailItems') as FormArray;
    }

    get eFactEnabled(): boolean {
        return this.#config.eFactActivated;
    }

    get eHealthBoxEnabled(): boolean {
        return this.#config.eHealthBoxActivated;
    }

    onSave(examDetail: ExamCompletionDetail) {
        this.#service
            .completeExam(examDetail)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(res => this.#dialogRef.close(res));
    }

    copy(id: string) {
        const element = document.getElementById(id);

        navigator.clipboard.writeText(element.innerText).then(() => {
            // Show success message
            document.getElementById('status').innerText = 'Copied to clipboard!';
        }).catch(err => {
            console.error('Failed to copy text: ', err);
            document.getElementById('status').innerText = 'Failed to copy!';
        });
    }
}

