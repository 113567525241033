import {
	Component,
	EventEmitter,
	inject, input,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { FileElement } from '../model/file-element';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { BehaviorSubject, forkJoin } from 'rxjs';
import {
	AudioPlayComponent,
	AudioRecordComponent,
	CameraComponent,
	SpeechNoteComponent,
} from '../modals';
import moment from 'moment';
import { FileService } from '../service/file.service';
import { FileSystemService } from '../../../statistic/file-system.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-file-explorer',
    templateUrl: './file-explorer.component.html',
    styleUrls: ['./file-explorer.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatTooltip,
        NgClass,
        MatMenuTrigger,
        MatMenu,
        MatMenuContent,
        MatMenuItem,
        AsyncPipe,
        DatePipe,
        TranslateModule,
    ],
})
export class FileExplorerComponent {
	now = new Date();

	public gridView = new BehaviorSubject(true);

	progress: any;
	canBeClosed = true;
	showCancelButton = true;
	uploading = false;
	uploadSuccessful = false;

	prescription = input<boolean>();
	visitNumber = input<string>();

	@Input() fileElements: FileElement[];
	@Input() canNavigateUp: boolean;
	@Input() path: string;
	@Input() patientID: string;
	@Input() modalMode: boolean;

	@ViewChild('file', { static: true }) file;
	public files: Set<File> = new Set();

	@Output() folderAdded = new EventEmitter<{ name: string }>();
	@Output() elementRemoved = new EventEmitter<FileElement>();
	@Output() elementRenamed = new EventEmitter<FileElement>();
	@Output() elementMoved = new EventEmitter<{
		element: FileElement;
		moveTo: FileElement;
	}>();
	@Output() navigatedDown = new EventEmitter<FileElement>();
	@Output() navigatedUp = new EventEmitter();
	@Output() uploadEvent = new EventEmitter<boolean>();
	private currentFolder: FileElement;

	private _dialog = inject(MatDialog);
	private _fs = inject(FileSystemService);
	private _fileService = inject(FileService);
	#fileCategory: string;


	getFolders = (
		fileElements: FileElement[],
		current: FileElement
	): FileElement[] =>
		fileElements.filter(file => file.folder && file !== current);

	openElement(element: FileElement) {
		if (['PNG', 'JPG', 'JPEG', 'GIF'].includes(element.fileType)) {
			this._fs.openImage(element).subscribe();
		}

		if (['MP3', 'WAV', 'AAC'].includes(element.fileType)) {
			this._dialog
				.open(AudioPlayComponent, {
					data: {
						audioFiles: this.fileElements.filter(elm =>
							['MP3', 'WAV', 'AAC'].includes(elm.fileType)
						),
						selected: element,
					},
					minWidth: '80%',
					height: '80%',
					panelClass: 'audio-play',
				})
				.afterClosed()
				.subscribe(console.log);
		}

		if (element.fileType === 'TXT')
			this._dialog
				.open(SpeechNoteComponent, {
					data: element,
					panelClass: 'speech-panel',
					disableClose: true,
				})
				.afterClosed()
				.subscribe();

		if (
			['DOCX', 'XLSX', 'DOC', 'XLS', 'PPT', 'PPTX', 'CSV'].includes(
				element.fileType
			)
		) {
			open(
				`/doc-reader/${element.uuid}.${element.fileType.toLowerCase()}`
			);
		}

		if (element.fileType === 'PDF')
			this._fs
				.open(
					`/upload-dir/${element.uuid}.${element.fileType.toLowerCase()}`
				)
				.subscribe();
	}

	deleteElement(element: FileElement) {
		this.elementRemoved.emit(element);
	}

	renameFile(ev, element) {
		element.name = ev.target.textContent;
		this.elementRenamed.emit(element);
	}

	renameOnEnter(ev, element) {
		if (ev.code === 'Enter') this.renameFile(ev, element);
	}

	navigate(element: FileElement) {
		if (element.folder) {
			this.currentFolder = element;
			this.navigatedDown.emit(element);
		}
	}

	navigateUp() {
		this.currentFolder = this.fileElements.find(
			value => value.uuid === this.currentFolder.parent
		);
		console.log(this.currentFolder);
		this.navigatedUp.emit();
	}

	moveElement(element: FileElement, moveTo: FileElement) {
		this.elementMoved.emit({ element: element, moveTo: moveTo });
	}

	createNewFileElement() {
		this.folderAdded.emit({ name: moment().format('YYYYMMDDHHmmss') });
	}

	uploadFile(file?: string) {
		this.#fileCategory = file;
		this.file.nativeElement.click();
	}

	takeSpeechNote() {
		this._dialog
			.open(SpeechNoteComponent, {
				panelClass: 'speech-panel',
				disableClose: true,
			})
			.afterClosed()
			.subscribe(result => {
				if (result) {
					const notes = new Set<any>();
					const blob = new Blob([result.note], {
						type: 'text/plan;charset=utf-8',
					});
					notes.add(
						new File(
							[blob],
							(result.noteName ||
								'Note_' + moment().format('YYYYMMDDHHmmss')) +
								'.txt',
							{ type: 'text/plain;charset=utf-8' }
						)
					);
					this.startUpload(notes);
				}
			});
	}

	onFilesAdded() {
		const files: { [key: string]: File } = this.file.nativeElement.files;
		for (const key in files) {
			if (!isNaN(parseInt(key, 0))) {
				this.files.add(files[key]);
			}
		}

		setTimeout(() => this.startUpload(this.files), 100);
	}

	downloadFile(file) {
		const filename = file.uuid + '.' + file.fileType.toLowerCase();
		window.open(`/api/documents/download/${filename}`, '_blank');
	}

	recordAudioFile() {
		this._dialog
			.open(AudioRecordComponent)
			.afterClosed()
			.subscribe(blob => {
				if (blob) {
					const audios: Set<any> = new Set<any>();
					audios.add(
						new File(
							[blob],
							moment().format('YYYYMMDDHHmmss') + '.wav',
							{ type: 'audio/wav' }
						)
					);
					this.startUpload(audios);
				}
			});
	}

	captureImage(file?: string) {
		this.#fileCategory = file;
		this._dialog
			.open(CameraComponent)
			.afterClosed()
			.subscribe(files => {
				if (files) this.startUpload(files);
			});
	}

	switchView() {
		this.gridView.next(!this.gridView.getValue());
	}

	openMenu(
		event: MouseEvent,
		element: FileElement,
		viewChild: MatMenuTrigger
	) {
		event.preventDefault();
		viewChild.openMenu();
	}

	getHRFileSizeString(fileSizeInBytes) {
		let i = -1;
		const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
		do {
			fileSizeInBytes = fileSizeInBytes / 1024;
			i++;
		} while (fileSizeInBytes > 1024);

		return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
	}

	startUpload(files: Set<File>) {
		// set the component state to "uploading"
		this.uploading = true;

		// start the upload and save the progress map
		this.progress = this.#fileCategory === 'prescription'
			? this._fileService.uploadPrescription(files, this.patientID, this.visitNumber())
			: this._fileService.uploadFiles(
				files,
				this.patientID,
				this.currentFolder ? this.currentFolder.uuid : 'root'
			);

		// convert the progress map into an array
		const allProgressObservables = [];
		for (const key in this.progress) {
			allProgressObservables.push(this.progress[key].progress);
		}

		// Adjust the state variables

		// The _dialog should not be closed while uploading
		this.canBeClosed = false;

		this.showCancelButton = true;

		// When all progress-observables are completed...
		forkJoin(allProgressObservables).subscribe(end => {
			// ... the _dialog can be closed again...
			this.canBeClosed = true;

			// ... the upload was successful...
			this.uploadSuccessful = true;

			// ... and the component is no longer uploading
			this.uploading = false;

			this.showCancelButton = false;

			this.uploadEvent.emit(this.uploadSuccessful);
			this.files.clear();
		});
	}
}
