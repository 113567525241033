import moment from 'moment';
import { assign, get, uniq } from 'lodash';
import {
	ColumnDataType,
	FormatRule,
	FormattingStyle,
	PatientWorkflow,
	ReportingPatientDTO,
	WorkflowItem,
} from '../model';
import { FileElement } from './file-explorer';

const emptyObj = {
	id: '',
	value: '',
	description: '',
};

export function waitingDuration(row: WorkflowItem): number {
	const patientArrival = moment(row.patientArrival).utc(true);
	return moment().diff(patientArrival, 'minute');
}

export function getAppType(logo: string): 'cvis' | 'ris' {
	return logo.includes('cvis') ? 'cvis' : 'ris';
}

export function getDisplayStyle(style: FormattingStyle): any {
	return style
		? {
			color: style.color,
			backgroundColor:
				style.backgroundColor === '#ffffff'
					? 'transparent'
					: style.backgroundColor,
			borderBottomColor:
				style.backgroundColor === '#ffffff'
					? 'transparent'
					: style.backgroundColor,
			fontWeight: style.bold ? 'bold' : 400,
			textDecoration:
				(style.underlined ? 'underline ' : '') +
				(style.strikethrough ? 'line-through ' : ''),
			fontStyle: style.italic ? 'italic' : 'normal',
		}
		: {};
}

export function isStringConditionFilled(
	formatRule: FormatRule,
	data: string,
	testValue: string,
): any {
	switch (formatRule) {
		case FormatRule.IS_EMPTY:
			return !data;
		case FormatRule.IS_NOT_EMPTY:
			return data && data.length != 0;
		case FormatRule.TEXT_NOT_CONTAINS:
			return (
				data &&
				!data.toLocaleLowerCase().includes(testValue.toLocaleLowerCase())
			);
		case FormatRule.TEXT_STARTS_WITH:
			return (
				data &&
				data.toLocaleLowerCase().startsWith(testValue.toLocaleLowerCase())
			);
		case FormatRule.TEXT_ENDS_WITH:
			return (
				data && data.toLocaleLowerCase().endsWith(testValue.toLocaleLowerCase())
			);
		case FormatRule.TEXT_IS_EXACTLY:
			return data === testValue;
		case FormatRule.TEXT_CONTAINS:
			return (
				data && data.toLocaleLowerCase().includes(testValue.toLocaleLowerCase())
			);
	}
}

export function isStatusConditionFilled(
	formatRule: FormatRule,
	data: string,
	testValue: string,
): any {
	switch (formatRule) {
		case FormatRule.TEXT_IS_EXACTLY:
			return data === testValue;
		case FormatRule.IS_EMPTY:
			return !data;
		case FormatRule.IS_NOT_EMPTY:
			return data && data.length != 0;
	}
}

export function isDateConditionFilled(
	formatRule: FormatRule,
	data: any,
	testValue: string,
): any {
	switch (formatRule) {
		case FormatRule.IS_EMPTY:
			return !data;
		case FormatRule.IS_NOT_EMPTY:
			return data && data.length != 0;
		case FormatRule.DATE_IS:
			return dateIsEqual(data, testValue);
	}
}

function dateIsEqual(data: any, testValue: string): boolean {
	switch (testValue) {
		case 'TODAY':
			return moment(data).isSame(moment(), 'd');
		case 'TOMORROW':
			return moment(data).isSame(moment().add(1, 'd'), 'd');
		case 'YESTERDAY':
			return moment(data).isSame(moment().subtract(1, 'd'), 'd');
		case 'PAST_WEEK':
			return moment(data).isBetween(
				moment().subtract(14, 'd'),
				moment().subtract(6, 'd'),
				'd',
				'()',
			);
		case 'PAST_MONTH':
			return moment(data).isBetween(
				moment().subtract(2, 'month'),
				moment().subtract(1, 'month'),
				'd',
				'()',
			);
		case 'PAST_YEAR':
			return moment(data).isBetween(
				moment().subtract(2, 'year'),
				moment().subtract(1, 'year'),
				'd',
				'()',
			);
		default:
			return false;
	}
}

export function checkCondition(
	colDataType: ColumnDataType,
	formatRule: FormatRule,
	data: any,
	firstValue: any,
	secondValue: any,
): boolean {
	switch (colDataType) {
		case ColumnDataType.DATE:
			return isDateConditionFilled(formatRule, data, firstValue);
		case ColumnDataType.NUMERIC:
			return isNumericConditionFilled(
				formatRule,
				data,
				firstValue,
				secondValue,
			);
		case ColumnDataType.BOOLEAN:
			return isBooleanConditionFilled(formatRule, data, firstValue);
		case ColumnDataType.STATUS:
			return isStatusConditionFilled(formatRule, data, firstValue);
		case ColumnDataType.STRING:
			return isStringConditionFilled(formatRule, data, firstValue);
		default:
			return false;
	}
}

export function isNumericConditionFilled(
	formatRule: FormatRule,
	data: number,
	firstValue: string,
	secondValue: string,
): any {
	switch (formatRule) {
		case FormatRule.GREATER_THAN:
			return data > parseFloat(firstValue);
		case FormatRule.GREATER_THAN_OR_EQUAL:
			return data >= parseFloat(firstValue);
		case FormatRule.LESS_THAN:
			return data < parseFloat(firstValue);
		case FormatRule.LESS_THAN_OR_EQUAL:
			return data <= parseFloat(firstValue);
		case FormatRule.IS_BETWEEN:
			return data >= parseFloat(firstValue) && data <= parseFloat(secondValue);
		case FormatRule.IS_NOT_BETWEEN:
			return !(
				data >= parseFloat(firstValue) && data <= parseFloat(secondValue)
			);
	}
}

export function isBooleanConditionFilled(
	formatRule: FormatRule,
	data: boolean,
	testValue: string,
): any {
	if (formatRule === FormatRule.TEXT_IS_EXACTLY) {
		return data.toString() === testValue.toString();
	}
	return false;
}

export function paymentColor(
	status: string,
	transparent: boolean = false,
): string {
	switch (status) {
		case 'PAID':
			return '#00FF00' + (transparent ? '60' : '');
		case 'NOT_PAID':
			return '#FF0000' + (transparent ? '60' : '');
		case 'PAID_PARTIALLY':
			return '#e6c34c' + (transparent ? '60' : '');
		case 'EXEMPT':
			return '#b1aaaa' + (transparent ? '60' : '');
		default:
			return '';
	}
}

export function shortName(name: string): string {
	if (name) {
		const tokens = name.split(' ');
		const fn = tokens[tokens.length - 1];
		const ln = tokens[0];

		return (ln.charAt(0) + '' + fn.charAt(0)).toUpperCase();
	} else return '-';
}

export function groupWorkflowData(data: WorkflowItem[]): PatientWorkflow[] {
	const patientData: PatientWorkflow[] = [];
	data.forEach((wi) => {
		wi.count = 1;
		const data = patientData.find(
			(it) =>
				it.patientID === wi.patientID &&
				moment(it.patientArrival).isSame(moment(wi.patientArrival), 'day'),
		);
		// let data = patientData.find(it => it.patientID === wi.patientID);
		if (data) {
			data.workflowItems.push(wi);
		} else {
			const d = Object.assign({}, wi as PatientWorkflow);
			d.workflowItems = [wi];
			patientData.push(d);
		}
	});

	return patientData.map((it) => {
		// it.totalAmount = patientWorkItemsTotalAmount(it);
		// it.paidAmount = sumPatientWorkItemsBy(it, 'paidAmount');
		it.reportingStatus = calculateReportingStatus(
			it.workflowItems.map((w) => w.reportingStatus),
		);
		it.sent = it.workflowItems
			.map((it) => it.sent)
			.reduce((b, acc) => acc || b, false);
		it.hasPrescription = it.workflowItems
			.map((it) => !!it.prescriptions)
			.reduce((b, acc) => acc || b, false);
		it.imagesAvailables = it.workflowItems
			.map((it) => it.imagesAvailables)
			.reduce((b, acc) => acc || b, false);
		it.billed = it.workflowItems
			.map((it) => it.billed)
			.reduce((b, acc) => acc || b, false);
		it.billedWithEFact = it.workflowItems
			.map((it) => it.billedWithEFact)
			.reduce((b, acc) => acc || b, false);
		it.sharedWithEHealthBox = it.workflowItems
			.map((it) => it.sharedWithEHealthBox)
			.reduce((b, acc) => acc || b, false);
		it.editingState = it.workflowItems
			.map((it) => it.editingState)
			.reduce((b, acc) => acc + b, '');
		it.examType = uniq(it.workflowItems.map((it) => it.examType)).reduce(
			(b, acc) => {
				return b === '' ? acc : `${acc}/${b}`;
			},
			'',
		);

		it.hasContrast = it.workflowItems
			.map((it) => it.contrast === 'C+')
			.reduce((b, acc) => acc || b, false);

		const codes = it.workflowItems.map((w) => {
			return w.laterality ? `${w.procedureCode} (${w.laterality})`: w.procedureCode;
		});
		it.procedureCode = codes.join('/ ');
		it.count = codes.length;
		return it;
	});
}

export function calculateReportingStatus(itemsStatuses: string[]): string {
	if (itemsStatuses.includes('SIGNED')) return 'SIGNED';
	else if (itemsStatuses.includes('FINISHED')) return 'FINISHED';
	else if (itemsStatuses.includes('VERIFIED')) return 'VERIFIED';
	else if (itemsStatuses.includes('TO_REVIEW')) return 'TO_REVIEW';
	else if (itemsStatuses.includes('TO_SIGN')) return 'TO_SIGN';
	else if (itemsStatuses.includes('TO_VERIFY')) return 'TO_VERIFY';
	else if (itemsStatuses.includes('DELIVERED')) return 'DELIVERED';
	else if (itemsStatuses.includes('IN_PROGRESS')) return 'IN_PROGRESS';
	else return 'WAITING';
}

export function bitmapToBlob(imageBitmap) {
	const canvas = document.createElement('canvas');

	canvas.width = imageBitmap.width;
	canvas.height = imageBitmap.height;

	const ctx = canvas.getContext('2d');
	ctx.drawImage(imageBitmap, 0, 0);

	return new Promise((resolve) => canvas.toBlob(resolve));
}

export function hasPermission(status: string, row: WorkflowItem): boolean {
	switch (status) {
		case 'authorizeExam':
			return !row.patientArrival;
		case 'startExam':
			return (
				row.patientArrival && !row.examinationStarted && !row.patientLeaving
			);
		case 'finishExam':
			return (
				row.patientArrival && row.examinationStarted && !row.examinationFinished
			);
		case 'exitPatient':
			return (
				(row.examinationFinished ||
					(row.patientArrival && !row.examinationStarted)) &&
				!row.patientLeaving
			);
		case 'printReport':
			return ['FINISHED', 'SIGNED', 'VERIFIED'].includes(row.reportingStatus);
		case 'printBooklet':
			return ['FINISHED', 'SIGNED', 'VERIFIED'].includes(row.reportingStatus);
		case 'createRdvForResult':
			return ['FINISHED', 'SIGNED', 'VERIFIED'].includes(row.reportingStatus);
		case 'editReport':
			return true;
		default:
			return false;
	}
}

export function getColumnStatuses(columnHeader: string): string[] {
	switch (columnHeader) {
		case 'PAYMENT_STATUS':
			return ['PAID', 'NOT_PAID', 'PAID_PARTIALLY', 'EXEMPT'];
		case 'REPORT_COMPLETION_STATUS':
			return ['WAITING', 'INPROGRESS', 'READY', 'DELIVERED'];
		case 'REPORTING_STATUS':
			return [
				'IN_PROGRESS',
				'TO_SIGN',
				'TO_REVIEW',
				'TO_TRANSCRIBE',
				'FINISHED',
				'VERIFIED',
				'SIGNED',
				'WAITING',
				'DELIVERED',
			];
		case 'PATIENT_STATUS':
			return [
				'NOT_YET',
				'BEFORE_TIME',
				'WAITING',
				'EXAM_STARTED',
				'EXAM_FINISHED',
			];
		case 'PATIENT_CLASS':
			return ['E', 'I', 'O'];
		default:
			return [];
	}
}

export function specialAttributes(header): boolean {
	return [
		'TOTAL_AMOUNT',
		'PAID_AMOUNT',
		'DISCOUNT',
		'LEFT_AMOUNT',
		'PAYMENT_STATUS',
		'PATIENTARRIVAL',
		'PROCEDURE_TYPE',
		'EXAM_START',
		'EXAM_FINISH',
		'PERFORMING_PHYSICIAN',
		'TECHNICIAN',
		'REPORTING_STATUS',
		'REPORT_COMPLETION_STATUS',
		'SPSSTATUS',
	].includes(header);
}

export function getConfidentialityColor(conf: string): string {
	switch (conf) {
		case 'U':
			return '#999';
		case 'V':
			return '#e5783b';
		case 'VIP':
			return '#11e596';
		default:
			return '#999';
	}
}

export function buildPatientDate(patientInfo: ReportingPatientDTO): any {
	let dob: string;
	const _dob = get(patientInfo, 'dateOfBirth');

	if (_dob === undefined || _dob === null || ['', '-'].includes(_dob))
		dob = '-';
	else dob = moment(_dob, 'YYYY-MM-DD').format('DD/MM/YYYY');

	return [
		{
			key: '%PATIENT::ID%',
			value: get(patientInfo, 'externalPatientID') || '-',
		},
		{
			key: '%PATIENT::FULL_NAME%',
			value: get(patientInfo, 'fullName') || '-',
		},
		{ key: '%PATIENT::AGE%', value: String(get(patientInfo, 'age') || '-') },
		{
			key: '%PATIENT::DOB%',
			value: dob,
		},
		{
			key: '%PATIENT::HEIGHT%',
			value: String(get(patientInfo, 'height') || '-'),
		},
		{
			key: '%PATIENT::WEIGHT%',
			value: String(get(patientInfo, 'weight') || '-'),
		},
		{ key: '%PATIENT::GENDER%', value: String(get(patientInfo, 'sex') || '-') },
		{ key: '%PATIENT::BMI%', value: String(get(patientInfo, 'imc') || '-') },
		{ key: '%PATIENT::BS%', value: String(get(patientInfo, 'sc') || '-') },
		{
			key: '%PATIENT::HISTORY%',
			value: get(patientInfo, 'additionalPatientHistory') || '-',
		},
		{ key: '%PATIENT::ALERTS%', value: get(patientInfo, 'alerts') || '-' },
		{ key: '%PATIENT::CIN%', value: get(patientInfo, 'cin') || '-' },
		{ key: '%PATIENT::PHONE%', value: get(patientInfo, 'phone') || '-' },
		{ key: '%PATIENT::ADDRESS%', value: get(patientInfo, 'street') || '-' },
		{ key: '%PATIENT::CITY%', value: get(patientInfo, 'city') || '-' },
		{
			key: '%PATIENT::POSTAL_CODE%',
			value: get(patientInfo, 'postalCode') || '-',
		},
		{
			key: '%PATIENT::COUNTRY%',
			value: get(patientInfo, 'country') || '-',
		},
	];
}

export function buildExamData(examData: any): any {
	return [
		{ key: '%EXAM::CODE%', value: get(examData, 'code') || '-' },
		{ key: '%EXAM::TECHNIQUE%', value: get(examData, 'technique') || '-' },
		{
			key: '%EXAM::REFERRING_PHYSICIAN%',
			value: get(examData, 'referringPhysician') || '-',
		},
		{
			key: '%EXAM::REFERRING_PHYSICIAN_ADDRESS%',
			value: get(examData, 'referringPhysicianAddress') || '-',
		},
		{ key: '%EXAM::DATE%', value: get(examData, 'examDate') || '-' },
		{
			key: '%EXAM::SIGNATURE_DATE%',
			value: get(examData, 'signatureDate') || '-',
		},
		{ key: '%EXAM::COMMENTS%', value: get(examData, 'comments') || '-' },
	];
}

export function buildRadiologistData(data: any): any {
	return [
		{ key: '%EXAM::RADIOLOGIST%', value: data.radiologist || '-' },
		{ key: '%EXAM::RADIOLOGIST_DESCRIPTION%', value: data.radiologistDescription || '-' },
	];
}

export function updateVariableValue(variable: string, value: string): any {
	const examVariables: { key: string; value: string }[] = JSON.parse(
		localStorage.getItem('EXAM_DATA'),
	);
	if (!examVariables) return;
	const exams = examVariables.map((it) => {
		if (it.key === variable) it.value = value;
		return it;
	});
	localStorage.setItem('EXAM_DATA', JSON.stringify(exams));
}

export function savePatientDataLocally(patientInfo: any, translate) {
	const patient = [
		{
			key: translate.instant('PATIENT_ID'),
			value: get(patientInfo, 'patientID'),
		},
		{
			key: translate.instant('PATIENT_NAME'),
			value: get(patientInfo, 'fullName'),
		},
		{ key: translate.instant('AGE'), value: get(patientInfo, 'age') },
		{
			key: translate.instant('DATE_OF_BIRTH'),
			value: get(patientInfo, 'dateOfBirth'),
		},
		{ key: translate.instant('BMI'), value: get(patientInfo, 'imc') },
		{ key: translate.instant('HEIGHT'), value: get(patientInfo, 'height') },
		{ key: translate.instant('WEIGHT'), value: get(patientInfo, 'weight') },
		{ key: translate.instant('SEX'), value: get(patientInfo, 'sex') },
		{ key: translate.instant('BSA'), value: get(patientInfo, 'sc') },
		{ key: translate.instant('ALERTS'), value: get(patientInfo, 'alerts') },
		{ key: translate.instant('CIN'), value: get(patientInfo, 'cin') },
		{ key: translate.instant('PHONE'), value: get(patientInfo, 'phone') },
	];

	localStorage.setItem('patient', JSON.stringify(patient));
}

export function removeLocalPatientData() {
	localStorage.removeItem('patient');
	localStorage.removeItem('PATIENT_DATA');
	localStorage.removeItem('EXAM_DATA');
}

export function getFileUrl(file: FileElement): string {
	return ['/upload-dir/', file.uuid + '.' + file.fileType.toLowerCase()].join(
		'',
	);
}

export function getReportStatus(status: string): string {
	switch (status) {
		case 'I_CL':
			return 'WAITING';
		case 'I_IP':
			return 'I_IN_PROGRESS';
		case 'I_CM':
			return 'INTERPRETED';
		case 'T_IP':
			return 'T_IN_PROGRESS';
		case 'T_CL':
			return 'T_WAITING';
		case 'T_CM':
			return 'TRANSCRIBED';
		case 'V_IP':
			return 'V_IN_PROGRESS';
		case 'V_CL':
			return 'V_WAITING';
		case 'V_CM':
			return 'VALIDATED';
		case 'R_IP':
			return 'R_IN_PROGRESS';
		case 'R_CL':
			return 'R_WAITING';
		case 'R_CM':
			return 'REVIEWED';
		case 'S_CL':
			return 'S_WAITING';
		case 'S_IP':
			return 'S_IN_PROGRESS';
		case 'S_CM':
			return 'SIGNED';
		case 'None':
			return 'NO_REPORT';
		default:
			return 'WAITING';
	}
}

export function getStatusColor(status: string): string {
	switch (status.split('_')[1]) {
		case 'CL':
			return '#484848';
		case 'CM':
			return '#016704';
		case 'IP':
			return '#9c0000';
		default:
			return '#484848';
	}
}

export function calculateSlotDuration(duration): string {
	if (duration < 10) {
		return '00:0' + duration;
	} else if (duration < 60) {
		return '00:' + duration;
	} else return '01:00';
}

export function deleteItemFromArray(arr: any[], item: any): any[] {
	arr.splice(arr.indexOf(item), 1);
	return arr;
}

export function buildClosingDays(arr: any[], ev: any): any[] {
	const source = ev.source;
	const selected = {
		name: source.value,
		checked: source.checked,
	};

	const day = arr.find((d) => d.name === selected.name);

	day.checked = !!selected.checked;

	return arr;
}

export function patchClosingDays(arr: any[], strDays: string): any {
	const closingDays = strDays.split(';');

	if (closingDays.length > 0)
		closingDays.forEach((dk) => {
			if (arr.find((d) => d.key === dk))
				arr.find((d) => d.key === dk).checked = true;
		});
}

export function getDays(lang: string): any[] {
	if (lang === 'en') {
		return [
			{ key: 'D1', name: 'Mon', checked: false },
			{ key: 'D2', name: 'Tue', checked: false },
			{ key: 'D3', name: 'Wed', checked: false },
			{ key: 'D4', name: 'Thu', checked: false },
			{ key: 'D5', name: 'Fri', checked: false },
			{ key: 'D6', name: 'Sat', checked: false },
			{ key: 'D0', name: 'Sun', checked: false },
		];
	} else {
		return [
			{ key: 'D1', name: 'Lun', checked: false },
			{ key: 'D2', name: 'Mar', checked: false },
			{ key: 'D3', name: 'Mer', checked: false },
			{ key: 'D4', name: 'Jeu', checked: false },
			{ key: 'D5', name: 'Ven', checked: false },
			{ key: 'D6', name: 'Sam', checked: false },
			{ key: 'D0', name: 'Dim', checked: false },
		];
	}
}

export function getCalendarViews(lang: string): any[] {
	if (lang === 'en') {
		return [
			{ id: 1, key: 'M', name: 'Month' },
			{ id: 2, key: 'W', name: 'Week' },
			{ id: 3, key: 'D', name: 'Day' },
			{ id: 4, key: 'L', name: 'List' },
			{ id: 5, key: 'P', name: 'Planning' },
			{ id: 6, key: 'G', name: 'Grid' },
			{ id: 7, key: 'G2', name: 'Grid 2 days' },
		];
	} else {
		return [
			{ id: 1, key: 'M', name: 'Mois' },
			{ id: 2, key: 'W', name: 'Semaine' },
			{ id: 3, key: 'D', name: 'Jour' },
			{ id: 4, key: 'L', name: 'Liste' },
			{ id: 5, key: 'P', name: 'Planning' },
			{ id: 6, key: 'G', name: 'Grille' },
			{ id: 7, key: 'G2', name: 'Grille 2 jours' },
		];
	}
}

export function getTimeSlots(): any[] {
	return [
		{ id: 1, value: '5' },
		{ id: 2, value: '10' },
		{ id: 3, value: '15' },
		{ id: 4, value: '20' },
		{ id: 5, value: '30' },
		{ id: 6, value: '45' },
		{ id: 7, value: '60' },
	];
}

export function getMomentTime(timeString: string, format: string): any {
	const hour = timeString.split(':')[0];
	const min = timeString.split(':')[1];

	return moment({ hour: Number(hour), minute: Number(min) }).format(format);
}

export function targetView(defaultView): string {
	switch (defaultView) {
		case 'D':
			return 'timeGridDay';
		case 'W':
			return 'timeGridWeek';
		case 'M':
			return 'dayGridMonth';
		case 'L':
			return 'listWeek';
		case 'P':
			return 'resourceTimelineDay';
		case 'G':
			return 'resourceTimeGridDay';
		case 'G2':
			return 'resourceTimeGrid2Day';
	}
}

export function addDays(date: Date, days: number): Date {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

export function findFromArrayById(arr: any[], id: number): any {
	return arr.find((value) => value.id === id);
}

export function buildProcedureAttributes(aets: any[], procedureCode: any): any {
	return {
		procedureCode: procedureCode,
		modality: procedureCode.modality,
		scheduledStationAETitle: aets[0],
		scheduledProcedureStepStatus: 'SCHEDULED',
		anatomicalOrientationType: 'BIPED',
		reasonForExam: procedureCode.reasonForExam,
		scheduledProcedureStepDescription: procedureCode.code,
	};
}
